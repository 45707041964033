import React from "react";
import Input from "../../../shared/components/FormElements/Input";
import Button from "../../../shared/components/FormElements/Button";

import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_OPTIONAL, VALIDATOR_EMAIL } from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";

const destinationInputHandler = (event) => {
  console.log("clicked");
};

const DestinationInput = (props) => {
  return (
    <div>
      <h3>
        Stop {props.destination} {props.date} info <button onClick={() => props.onDelete(props.id)}> Delete</button>
      </h3>
      <Input
        id="destination"
        element="input"
        label="Destination: Please use format Atlanta, GA "
        validators={[VALIDATOR_MINLENGTH(1)]}
        errorText="Please enter a valid city."
        onInput={props.inputHandler}
        initialValue={props.destination}
        initialValid={true}
        className="grid-item"
      />

      <Input
        id="loadNumber"
        element="input"
        type="text"
        label="Load Number"
        validators={[VALIDATOR_OPTIONAL]}
        errorText="Please enter a valid number."
        onInput={props.inputHandler}
        initialValue={props.loadNumber}
        className="grid-item"
        initialValid={true}
      />

      <Input
        id="email"
        element="input"
        type="text"
        label="Email - optional (for updates)"
        validators={[VALIDATOR_EMAIL]}
        errorText="Please enter a valid email."
        onInput={props.inputHandler}
        initialValue={props.email}
        className="grid-item"
        initialValid={true}
      />

      <Input
        id="date"
        element="input"
        value="Solo"
        validators={[VALIDATOR_OPTIONAL]}
        type="Date"
        label="Delivery date"
        onInput={props.inputHandler}
        className="grid-item"
        initialValue={props.date}
      />

      <Input
        id="time"
        element="input"
        value=""
        validators={[VALIDATOR_OPTIONAL]}
        type="time"
        label="Delivery Time"
        onInput={props.inputHandler}
        className="grid-item"
        initialValue={props.time}
      />
    </div>
  );
};

export default DestinationInput;

/*
      
*/
