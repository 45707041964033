import React from "react";

import Card from "../../../shared/components/UIElements/Card";
import TruckItem from "./TruckItem";
import "./TruckList.css";
//import UserTrucks from "../UserTrucks";
import Button from "../../../shared/components/FormElements/Button";

const TruckList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="place-list">
        <Card>
          <h2> No trucks found. Maybe add one? </h2>
        </Card>
      </div>
    );
  }

  return (
    <div className="truck-list cardcontainer">
      {props.items.map((truck) => (
        <TruckItem
          key={truck.id}
          id={truck.id}
          driver2={truck.driver2}
          driver={truck.driver}
          destination={truck.destination}
          truckNumber={truck.truckNumber}
          creatorId={truck.creator}
          coordinates={truck.destinationCoordinates}
          onDelete={props.onDeleteTruck}
          speed={truck.speed}
          distance={truck.distance}
          timezone={truck.timezone}
          locdate={truck.locdate}
          located_atDate={truck.located_atDate}
          located_at={truck.located_at}
          eta={truck.eta}
          updateString={truck.updateString}
          location={truck.location}
          onDutyDriver={truck.onDutyDriver}
          className="carddiv"
          destinations={truck.destinationObj}
          aval_shiftOn={truck.aval_shiftOn}
          aval_cycleOn={truck.aval_cycleOn}
          aval_driveOn={truck.aval_driveOn}
          notes={truck.notes}
          aval_cycle={truck.aval_cycle}
          aval_drive={truck.aval_drive}
          aval_shift={truck.aval_shift}
          restartEnd={truck.restartEnd}
          restartEndString={truck.restartEndString}
          cycleResetString={truck.cycleResetString}
          driverid={truck.driverid}
          isLate={truck.isLate}
          isEmpty={truck.isEmpty}
          arrived={truck.arrived}
          cycleReset={truck.cycleReset}
          aval_cycle2={truck.aval_cycle2}
          aval_drive2={truck.aval_drive2}
          aval_shift2={truck.aval_shift2}
        />
      ))}
    </div>
  );
};

export default TruckList;
