import React, { useCallback, useReducer, useContext } from "react";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_OPTIONAL, VALIDATOR_MIN, VALIDATOR_EMAIL } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import "./NewTruck.css";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/components/context/auth-context";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHistory } from "react-router-dom";

const NewTruck = () => {
  //test
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [formState, inputHandler] = useForm(
    {
      truckNumber: {
        value: "",
        isValid: false,
      },
      destination: {
        value: "",
        isValid: false,
      },
      driver2: {
        value: "Solo",
        isValid: true,
      },
    },
    false
  );

  const history = useHistory();
  const truckSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/trucks`,
        "POST",
        JSON.stringify({
          truckNumber: formState.inputs.truckNumber,
          destination: formState.inputs.destination,
          driver: formState.inputs.driver,
          driver2: formState.inputs.driver2,
          email: formState.inputs.email,
          date: formState.inputs.date,
          time: formState.inputs.time,
          loadNumber: formState.inputs.loadNumber,
          notes: formState.inputs.notes,
          driverid: formState.inputs.driverid,
          driverid2: formState.inputs.driverid2,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="truck-form" onSubmit={truckSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input
          id="truckNumber"
          element="input"
          type="text"
          label="Truck Number"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid truck number."
          onInput={inputHandler}
        />

        <Input
          id="driver"
          element="input"
          type="text"
          label="Driver"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid driver name."
          onInput={inputHandler}
        />

        <Input
          id="driverid"
          element="input"
          type="text"
          label="Driver Keeptruckin ID "
          validators={[VALIDATOR_MIN(1)]}
          errorText="Please enter a valid Keeptruckin driver id."
          onInput={inputHandler}
        />

        <Input
          id="driver2"
          element="input"
          value="Solo"
          placeholder="Solo"
          validators={[VALIDATOR_OPTIONAL]}
          type="text"
          label="Second Driver"
          initialValue="Solo"
          onInput={inputHandler}
        />
        <Input
          id="driverid2"
          element="input"
          type="text"
          label="Second Driver Keeptruckin ID "
          validators={[VALIDATOR_OPTIONAL]}
          initialValue="00006"
          errorText="Please enter a valid Keeptruckin driver id."
          onInput={inputHandler}
        />

        <Input
          id="destination"
          element="input"
          label="Destination"
          validators={[VALIDATOR_MINLENGTH(3)]}
          errorText="Please enter a valid destination (at least 3 characters)."
          initialValue="empty"
          onInput={inputHandler}
        />

        <Input
          id="date"
          element="input"
          value="Solo"
          validators={[VALIDATOR_EMAIL]}
          type="date"
          label="Date"
          onInput={inputHandler}
          initialValue="email@email.com"
        />

        <Input
          id="time"
          element="input"
          value=""
          validators={[VALIDATOR_OPTIONAL]}
          type="time"
          label="Time"
          initialValue="07:00"
          onInput={inputHandler}
        />

        <Input
          id="loadNumber"
          element="input"
          value=""
          validators={[VALIDATOR_OPTIONAL]}
          type="text"
          label="Load Number"
          onInput={inputHandler}
        />

        <Input
          id="email"
          element="input"
          value=""
          validators={[VALIDATOR_OPTIONAL]}
          type="email"
          label="Email"
          onInput={inputHandler}
          initialValue="email@email.com"
        />

        <Input
          id="notes"
          element="textarea"
          value=""
          validators={[VALIDATOR_OPTIONAL]}
          type="text"
          label="Notes"
          onInput={inputHandler}
          initialValue=" "
        />

        <Button type="submit" disabled={!formState.isValid}>
          ADD TRUCK
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewTruck;
