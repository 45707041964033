import React, { useEffect, useState } from "react";
//import { useParams } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import Driver from "./components/Driver";
import "./Safety.css";

const Safety = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedDrivers, setLoadedDrivers] = useState();
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_API_URL}/api/drivers/`);
        console.log("request");
        setLoadedDrivers(responseData.drivers);
      } catch (err) {}
    };

    fetchDrivers();

    const interval = setInterval(fetchDrivers, 100000);
    return () => clearInterval(interval);
  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          {" "}
          <LoadingSpinner />{" "}
        </div>
      )}
      {!isLoading && loadedDrivers && (
        <div className="driversDiv">
          {loadedDrivers.map((driver) => (
            <Driver
              name={driver.name}
              speeding={driver.speeding}
              legalSpeed={driver.legalSpeed}
              notes={driver.notes}
              hardAcc={driver.hardAcc}
              hardBrake={driver.hardBrake}
              hardCorner={driver.hardKorner}
              score={driver.score}
              key={driver.id}
              id={driver.id}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default Safety;
