import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_OPTIONAL } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/components/context/auth-context";
import "./UpdateTruck.css";
//import Destination from "./components/Destination";
import DestinationInput from "./components/DestinationInput";

const UpdateTruck = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedTruck, setLoadedTruck] = useState();
  const truckId = useParams().truckId;
  const history = useHistory();

  const [formState, inputHandler, setFormData] = useForm(
    {
      truckNumber: { value: "", isValid: false },
      driver: {
        value: "",
        isValid: false,
      },
      driver2: {
        value: "",
        isValid: false,
      },
      destination: {
        value: "",
        isValid: false,
      },
      isEmpty: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  //logic runs with every re-render cycle but identifiedTruck doesn't change
  // (always finding the same oobject)
  useEffect(() => {
    const fetchTruck = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_API_URL}/api/trucks/${truckId}`);
        setLoadedTruck(responseData.truck);
        setFormData(
          {
            truckNumber: {
              value: responseData.truck.truckNumber,
              isValid: true,
            },
            driver: {
              value: responseData.truck.driver,
              isValid: true,
            },
            driver2: {
              value: responseData.truck.driver2,
              isValid: true,
            },
            destination: {
              value: responseData.truck.destination,
              isValid: true,
            },
            loadNumber: {
              value: responseData.truck.destinationObj[0].loadNumber,
              isValid: true,
            },
            email: {
              value: responseData.truck.destinationObj[0].email,
              isValid: true,
            },
            notes: {
              value: responseData.truck.notes,
              isValid: true,
            },
          },
          true
        );
      } catch (err) {}
    };
    fetchTruck();
  }, [sendRequest, truckId, setFormData]);

  //setForm data doesn't change through re-render cycle because it is wrapped with useCallback in the form-hook.js

  const truckUpdateSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/trucks/${truckId}`,
        "PATCH",
        JSON.stringify({
          truckNumber: formState.inputs.truckNumber.value,
          driver: formState.inputs.driver.value,
          driver2: formState.inputs.driver2.value,
          destination: formState.inputs.destination.value,
          loadNumber: formState.inputs.loadNumber,
          email: formState.inputs.email,
          date: formState.inputs.date,
          time: formState.inputs.time,
          notes: formState.inputs.notes.value,
          driverid: formState.inputs.driverid.value,
          driverid2: formState.inputs.driverid2.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      history.push("/" + auth.userId + "/trucks");
    } catch (err) {}
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />{" "}
      </div>
    );
  }

  if (!loadedTruck && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find truck!</h2>
        </Card>
      </div>
    );
  }

  const handleDelete = (id) => {
    alert("Button Clicked!");
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && loadedTruck && (
        <form className="truck-form" onSubmit={truckUpdateSubmitHandler}>
          <Input
            id="truckNumber"
            element="input"
            type="text"
            label="Truck number"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid number."
            onInput={inputHandler}
            initialValue={loadedTruck.truckNumber}
            initialValid={true}
            className="grid-item"
          />

          <div className="destinationDiv">
            {!!loadedTruck.destinationObj
              ? loadedTruck.destinationObj.map((loaded, key) => (
                  <DestinationInput
                    truck={loadedTruck.id}
                    destination={loaded.destination}
                    targetDate={loaded.targetDate}
                    loadNumber={loaded.loadNumber}
                    email={loaded.email}
                    date={loaded.date}
                    time={loaded.time}
                    timezone={loadedTruck.timezone}
                    key={loaded.destination + loaded.loadNumber}
                    inputHandler={inputHandler}
                    onDelete={handleDelete}
                    value={loadedTruck.id}
                  />
                ))
              : ""}
          </div>
          <Input
            id="trailelNumber"
            element="input"
            type="text"
            label="Trailer Number"
            validators={[VALIDATOR_OPTIONAL]}
            errorText="Please enter a valid number."
            onInput={inputHandler}
            initialValue={loadedTruck.truckNumber}
            initialValid={true}
            className="grid-item"
          />
          <Input
            id="driver"
            element="input"
            label="Driver"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter a valid driver (min. 5 characters)."
            onInput={inputHandler}
            initialValue={loadedTruck.driver}
            initialValid={true}
            className="grid-item"
          />
          <Input
            id="driverid"
            element="input"
            type="text"
            label="Driver Keeptruckin ID "
            initialValue={loadedTruck.driverid}
            validators={[VALIDATOR_OPTIONAL]}
            errorText="Please enter a valid truck number."
            onInput={inputHandler}
          />
          <Input
            id="driver2"
            element="input"
            label="Second driver"
            validators={[VALIDATOR_OPTIONAL]}
            errorText="Please enter a valid driver (min. 1 character)."
            onInput={inputHandler}
            initialValue={loadedTruck.driver2}
            initialValid={true}
            className="grid-item"
          />
          <Input
            id="driverid2"
            element="input"
            type="text"
            label="Driver Keeptruckin ID "
            initialValue={loadedTruck.driverid2 || "000006"}
            validators={[VALIDATOR_OPTIONAL]}
            errorText="Please enter a valid truck number."
            onInput={inputHandler}
          />
          <Input
            id="notes"
            element="textarea"
            value=""
            validators={[VALIDATOR_OPTIONAL]}
            type="text"
            label="Notes"
            initialValue={loadedTruck.notes}
            onInput={inputHandler}
          />
          <Button type="submit" disabled={!formState.isValid}>
            UPDATE TRUCK
          </Button>
        </form>
      )}
    </React.Fragment>
  );
};

export default UpdateTruck;
