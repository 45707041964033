import {useState, useCallback, useRef, useEffect} from 'react'
//hooks name to start with use
export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    //storing data across rerander cycles. Don't want to rerender when data changes
    const activeHttpRequests = useRef([])
 //sendRequest wrapped in usecallback so it doesn't get re-created whenever hook re-runs
    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
        setIsLoading(true)
        const httpAbortCtrll = new AbortController();
        activeHttpRequests.current.push(httpAbortCtrll)
        try {const response = await fetch(url, {method, body, headers, signal: httpAbortCtrll.signal})

        const responseData = await response.json();
        activeHttpRequests.current = activeHttpRequests.current.filter(reqCtrl => reqCtrl !== httpAbortCtrll)

        if (!response.ok) {
          throw new Error(responseData.message);
        }
        setIsLoading(false)

    return responseData
    } catch (err) {
            setError(err.message)
            setIsLoading(false)

            throw err;

        }
        
        
    },[])

    const clearError = () => {
        setError(null)
    }

    useEffect(() => {
        //function inside (first) useeffect used as clean up function before useEffect runs again or when the component that uses useeffect unmounts
        return () => {
            activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
        }
    }, [])
    return {isLoading, error, sendRequest, clearError}
}