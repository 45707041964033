import React from "react";

const destinationHandler = (event) => {
  console.log("clicked");
};

// {props.truck}
//truck={props.id}
/*destination={destinations.destination}
targetDate={destinations.targetDate}
loadNumber={destinations.loadNumber}
email={destinations.email}
date={destinations.date}
time={destinations.time}
timezone={destinations.timezone}
key={destinations.destination + destinations.loadNumber}
*/

const Destination = (props) => {
  return (
    <React.Fragment>
      <p onClick={destinationHandler}>
        {props.destination} ({props.loadNumber} {props.targetDate})
      </p>
    </React.Fragment>
  );
};

export default Destination;
