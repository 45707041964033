import React, { useState, useContext, useEffect } from "react";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import "./TruckItem.css";
import Card from "../../../shared/components/UIElements/Card";
import Button from "../../../shared/components/FormElements/Button";
import Modal from "../../../shared/components/UIElements/Modal";
import { AuthContext } from "../../../shared/components/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Input from "../../../shared/components/FormElements/Input";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_OPTIONAL } from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";
import Destination from "./Destination";
/* <Button inverse onClick={openMapHandler}>
              ADD STOP
            </Button>
*/
import { GiClockwork, GiOverdrive, GiCheckeredFlag, GiOnSight, GiHoodedFigure } from "react-icons/gi";
import { GoClock } from "react-icons/go";
import { IconContext } from "react-icons";
import { FaLocationArrow } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";

const TruckItem = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [showMap, setShowMap] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);
  const showDeletewarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const secondsToHours = (seconds) => {
    let hours = Math.floor(seconds / 3600) < 10 ? "0" + Math.floor(seconds / 3600) : Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60) < 10 ? "0" + Math.floor((seconds % 3600) / 60) : Math.floor((seconds % 3600) / 60);

    return `${hours}:${minutes}`;
  };

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_API_URL}/api/trucks/${props.id}`, "DELETE", null, {
        Authorization: "Bearer " + auth.token,
      });
      props.onDelete(props.id);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      destination: {
        value: "",
        isValid: false,
      },
      loadNumber: {
        value: "Solo",
        isValid: true,
      },
      time: {
        value: "Solo",
        isValid: true,
      },
      date: {
        value: "Solo",
        isValid: true,
      },

      notes: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const destinationSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/trucks/dest/${props.id}`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email,
          date: formState.inputs.date,
          time: formState.inputs.time,
          loadNumber: formState.inputs.loadNumber,
          destination: formState.inputs.destination,
          notes: formState.inputs.notes,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showMap}
        onCancel={closeMapHandler}
        //header={props.address}
        contentClass="truck-item__modal-content"
        footerClass="truck-item__modal-actions"
        footer={<Button onClick={closeMapHandler}> CANCEL </Button>}
        footer={<Button onClick={destinationSubmitHandler}> SUBMIT </Button>}
      >
        <div className="map-container">
          <Input
            id="destination"
            element="input"
            label="Destination"
            validators={[VALIDATOR_MINLENGTH(3)]}
            errorText="Please enter a valid destination (at least 3 characters)."
            onInput={inputHandler}
          />

          <Input id="date" element="input" value="Solo" validators={[VALIDATOR_OPTIONAL]} type="date" label="Date" onInput={inputHandler} />

          <Input
            id="time"
            element="input"
            value=""
            validators={[VALIDATOR_OPTIONAL]}
            type="time"
            label="Time"
            initialValue="07:00"
            onInput={inputHandler}
          />

          <Input
            id="loadNumber"
            element="input"
            value=""
            validators={[VALIDATOR_OPTIONAL]}
            type="text"
            label="Load Number"
            onInput={inputHandler}
          />

          <Input id="email" element="input" value="" validators={[VALIDATOR_OPTIONAL]} type="email" label="Email" onInput={inputHandler} />
        </div>
      </Modal>
      <Modal
        show={showConfirmModal}
        header="Are you sure?"
        onCancel={cancelDeleteHandler}
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              {" "}
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              {" "}
              DELETE
            </Button>
          </React.Fragment>
        }
      >
        <p> Do you wanto to proceed and delete this truck?</p>
      </Modal>

      <div className="truck-item">
        <Card className="truck-item__content">
          {isLoading && <LoadingSpinner asOverlay />}
          <div className="truck-item__info">
            <span>
              {!!props.speed && props.driver2 === "Solo" ? (
                <span>
                  {" "}
                  <span className="onDuty right">
                    <FaLocationArrow /> {props.truckNumber} {props.speed}
                    mph{" "}
                  </span>
                </span>
              ) : (
                props.driver2 === "Solo" && <span className="offDuty"> {props.truckNumber} 0 mph </span>
              )}{" "}
            </span>
            {props.onDutyDriver && props.driver2 == "Solo" ? (
              <span>
                {" "}
                <span className="offDuty mobileOpt2"> {props.onDutyDriver} </span>
                <span className="offDuty2">
                  <IconContext.Provider value={{ size: "1.1rem", className: "global-class-name" }}>
                    <span>
                      <GiOverdrive />
                    </span>
                  </IconContext.Provider>{" "}
                  <span className="mobileOpt">Drive: </span> {secondsToHours(props.aval_driveOn)}{" "}
                  <IconContext.Provider value={{ size: "1.1rem", className: "global-class-name" }}>
                    <span>
                      <GiCheckeredFlag />
                    </span>
                  </IconContext.Provider>{" "}
                  <span className="mobileOpt">Shift: </span> {secondsToHours(props.aval_shiftOn)}{" "}
                  <IconContext.Provider value={{ size: "1.1rem", className: "global-class-name" }}>
                    <span>
                      <GiOverdrive />
                    </span>
                  </IconContext.Provider>{" "}
                  <span className="mobileOpt">Cycle: </span>
                  {secondsToHours(props.aval_cycleOn)}
                </span>
              </span>
            ) : (
              <span></span>
            )}

            {props.onDutyDriver && props.driver2 !== "Solo" && props.onDutyDriver === props.driver ? (
              <div>
                {props.speed > 3 && (
                  <span className="onDuty right">
                    <FaLocationArrow /> {props.truckNumber} {props.speed}
                    mph{" "}
                  </span>
                )}
                {props.speed < 3 && (
                  <span className="offDuty right">
                    {" "}
                    {props.truckNumber} {props.speed} mph
                  </span>
                )}
                <span className="offDuty mobileOpt">{props.onDutyDriver} </span>{" "}
                {secondsToHours(props.aval_shiftOn) ? (
                  <span className="offDuty2">
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiOverdrive />
                      </span>
                    </IconContext.Provider>{" "}
                    <span className="mobileOpt2">Drive: </span> {secondsToHours(props.aval_driveOn)}{" "}
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiCheckeredFlag />
                      </span>
                    </IconContext.Provider>
                    <span className="mobileOpt2"> Shift: </span> {secondsToHours(props.aval_shiftOn)}{" "}
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiOverdrive />
                      </span>
                    </IconContext.Provider>
                    <span className="mobileOpt2"> Cycle: </span>
                    {secondsToHours(props.aval_cycleOn)}
                  </span>
                ) : (
                  ""
                )}
                <p>
                  {" "}
                  <span className=" onDuty right numberPlaceholder">
                    <FaLocationArrow /> {props.truckNumber} {props.speed}
                    mph{" "}
                  </span>{" "}
                  <span className="offDuty3"> {props.driver2} </span>
                  {secondsToHours(props.aval_shift2) ? (
                    <span className="offDuty3">
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiOverdrive />
                        </span>
                      </IconContext.Provider>{" "}
                      <span className="mobileOpt2">Drive: </span> {secondsToHours(props.aval_drive2)}{" "}
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiCheckeredFlag />
                        </span>
                      </IconContext.Provider>
                      <span className="mobileOpt2"> Shift: </span> {secondsToHours(props.aval_shift2)}{" "}
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiOverdrive />
                        </span>
                      </IconContext.Provider>
                      <span className="mobileOpt2"> Cycle: </span>
                      {secondsToHours(props.aval_cycle2)}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              <span> </span>
            )}

            {props.onDutyDriver && props.driver2 !== "Solo" && props.onDutyDriver === props.driver2 ? (
              <div>
                {props.speed > 3 && (
                  <span className="onDuty right">
                    <FaLocationArrow /> {props.truckNumber} {props.speed}
                    mph{" "}
                  </span>
                )}
                {props.speed < 3 && (
                  <span className="offDuty right">
                    {" "}
                    {props.truckNumber} {props.speed} mph
                  </span>
                )}
                <span className="offDuty mobileOpt">{props.onDutyDriver} </span>{" "}
                {secondsToHours(props.aval_shiftOn) ? (
                  <span className="offDuty2">
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiOverdrive />
                      </span>
                    </IconContext.Provider>{" "}
                    <span className="mobileOpt2">Drive: </span> {secondsToHours(props.aval_driveOn)}{" "}
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiCheckeredFlag />
                      </span>
                    </IconContext.Provider>
                    <span className="mobileOpt2"> Shift: </span> {secondsToHours(props.aval_shiftOn)}{" "}
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiOverdrive />
                      </span>
                    </IconContext.Provider>
                    <span className="mobileOpt2"> Cycle: </span>
                    {secondsToHours(props.aval_cycleOn)}
                  </span>
                ) : (
                  ""
                )}
                <p>
                  {" "}
                  <span className=" onDuty right numberPlaceholder">
                    <FaLocationArrow /> {props.truckNumber} {props.speed}
                    mph{" "}
                  </span>{" "}
                  <span className="offDuty3"> {props.driver} </span>
                  {secondsToHours(props.aval_shift) ? (
                    <span className="offDuty3">
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiOverdrive />
                        </span>
                      </IconContext.Provider>{" "}
                      <span className="mobileOpt2">Drive: </span> {secondsToHours(props.aval_drive)}{" "}
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiCheckeredFlag />
                        </span>
                      </IconContext.Provider>
                      <span className="mobileOpt2"> Shift: </span> {secondsToHours(props.aval_shift)}{" "}
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiOverdrive />
                        </span>
                      </IconContext.Provider>
                      <span className="mobileOpt2"> Cycle: </span>
                      {secondsToHours(props.aval_cycle)}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              <span> </span>
            )}

            {!props.onDutyDriver && props.driver2 === "Solo" && props.aval_cycle ? (
              <span className="offDuty3">
                {" "}
                {props.driver}{" "}
                <span>
                  <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                    <span>
                      <GiOverdrive />
                    </span>
                  </IconContext.Provider>{" "}
                  <span className="mobileOpt">Drive: </span> {secondsToHours(props.aval_drive)}{" "}
                  <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                    <span>
                      <GiCheckeredFlag />
                    </span>
                  </IconContext.Provider>
                  <span className="mobileOpt">Shift: </span> {secondsToHours(props.aval_shift)}{" "}
                  <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                    <span>
                      <GiOverdrive />
                    </span>
                  </IconContext.Provider>{" "}
                  <span className="mobileOpt">Cycle: </span>
                  {secondsToHours(props.aval_cycle)}
                </span>
              </span>
            ) : (
              <span> </span>
            )}
            {!props.onDutyDriver && props.driver2 !== "Solo" ? (
              <div>
                {props.speed > 3 && (
                  <span className="onDuty right">
                    <FaLocationArrow /> {props.truckNumber} {props.speed} mph{" "}
                  </span>
                )}
                {props.speed < 3 && (
                  <span className="offDuty right">
                    {" "}
                    {props.truckNumber} {props.speed} mph
                  </span>
                )}
                <span className="offDuty3 mobileOpt">{props.driver} </span>{" "}
                {secondsToHours(props.aval_shift) ? (
                  <span className="offDuty3">
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiOverdrive />
                      </span>
                    </IconContext.Provider>{" "}
                    <span className="mobileOpt2">Drive: </span> {secondsToHours(props.aval_drive)}{" "}
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiCheckeredFlag />
                      </span>
                    </IconContext.Provider>
                    <span className="mobileOpt2"> Shift: </span> {secondsToHours(props.aval_shift)}{" "}
                    <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                      <span>
                        <GiOverdrive />
                      </span>
                    </IconContext.Provider>
                    <span className="mobileOpt2"> Cycle: </span>
                    {secondsToHours(props.aval_cycle)}
                  </span>
                ) : (
                  ""
                )}
                <p>
                  {" "}
                  <span className=" onDuty right numberPlaceholder">
                    {props.truckNumber} {props.speed}
                    mph{" "}
                  </span>{" "}
                  <span className="offDuty3"> {props.driver2} </span>
                  {secondsToHours(props.aval_shift2) ? (
                    <span className="offDuty3">
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiOverdrive />
                        </span>
                      </IconContext.Provider>{" "}
                      <span className="mobileOpt2">Drive: </span> {secondsToHours(props.aval_drive2)}{" "}
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiCheckeredFlag />
                        </span>
                      </IconContext.Provider>
                      <span className="mobileOpt2"> Shift: </span> {secondsToHours(props.aval_shift2)}{" "}
                      <IconContext.Provider value={{ size: "1.2rem", className: "global-class-name" }}>
                        <span>
                          <GiOverdrive />
                        </span>
                      </IconContext.Provider>
                      <span className="mobileOpt2"> Cycle: </span>
                      {secondsToHours(props.aval_cycle2)}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}

            {props.restartEnd && props.restartEndString !== "completed" && props.driver2 === "Solo" ? (
              <div className="reset10hr">
                <IconContext.Provider value={{ size: "1.15rem", className: "global-class-name" }}>
                  <span>
                    <GiClockwork />{" "}
                  </span>
                </IconContext.Provider>{" "}
                10h reset: {props.restartEndString}
              </div>
            ) : (
              <div> </div>
            )}

            {(!props.restartEnd || props.restartEndString == "completed") && props.driver2 === "Solo" && (
              <div className="reset10hr placeholder">10h reset : </div>
            )}

            {props.cycleReset && props.cycleResetString !== "completed" && props.driver2 === "Solo" ? (
              <div className="reset10hr">
                <IconContext.Provider value={{ size: "1.15rem", className: "global-class-name" }}>
                  <span>
                    <GiClockwork />{" "}
                  </span>
                </IconContext.Provider>{" "}
                70h reset: {props.cycleResetString}
              </div>
            ) : (
              <div> </div>
            )}

            {(!props.cycleReset || props.cycleResetString == "completed") && props.driver2 === "Solo" && (
              <div className="reset10hr placeholder">70h reset : </div>
            )}

            <div className="container">
              <div>
                <h5>
                  <span>
                    {" "}
                    <GiOnSight />{" "}
                  </span>
                  Location{" "}
                </h5>
                <p> {props.location}</p>{" "}
                <p className="locdate">
                  {" "}
                  {props.located_at} {props.located_atDate}
                </p>
              </div>
              <div className="dropdown">
                {" "}
                <h5>
                  {" "}
                  <ImLocation2 /> Destination:{" "}
                </h5>
                <p>{props.destination}</p>
              </div>
              <div className="dropdown-content">
                {props.destinations.map((destinations, key) => (
                  <Destination
                    truck={props.id}
                    destination={destinations.destination}
                    targetDate={destinations.targetDate}
                    loadNumber={destinations.loadNumber}
                    email={destinations.email}
                    date={destinations.date}
                    time={destinations.time}
                    timezone={destinations.timezone}
                    key={destinations.destination + destinations.loadNumber}
                  />
                ))}
              </div>
              <div>
                <h5>
                  <span>
                    <GiHoodedFigure />{" "}
                  </span>
                  ETA:
                </h5>
                {props.speed > 0 && !props.isEmpty ? (
                  <div className={`${props.isLate}`}>
                    <p>{props.updateString} </p>{" "}
                  </div>
                ) : (
                  <h5> </h5>
                )}

                {props.distance > -1 && !(props.isEmpty || props.arrived) ? (
                  <div>
                    {" "}
                    <span className={`${props.isLate}`}> {props.distance} miles out</span>
                  </div>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>
          <div className="truck-item__actions">
            <div>{props.notes} </div>
            {auth.isLoggedIn && <Button to={`/trucks/${props.id}`}> EDIT</Button>}

            {auth.userId === props.creatorId && (
              <Button danger onClick={showDeletewarningHandler}>
                {" "}
                DELETE
              </Button>
            )}
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default TruckItem;
