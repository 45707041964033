import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TruckList from "../pages/components/TruckList";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const UserTrucks = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const userId = useParams().userId;
  const [loadedTrucks, setLoadedTrucks] = useState();

  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_API_URL}/api/trucks/user/${userId}`);
        setLoadedTrucks(responseData.trucks);
      } catch (err) {}
    };

    fetchTrucks();
    setTimeout(() => {
      fetchTrucks();
    }, 32000);
    const interval = setInterval(fetchTrucks, 100000);
    return () => clearInterval(interval);
  }, [sendRequest, userId]);

  const truckDeleteHandler = (deletedTruckId) => {
    setLoadedTrucks((prevTrucks) => prevTrucks.filter((truck) => truck.id !== deletedTruckId));
  };
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          {" "}
          <LoadingSpinner />{" "}
        </div>
      )}
      {!isLoading && loadedTrucks && <TruckList items={loadedTrucks} onDeleteTruck={truckDeleteHandler} />}
    </React.Fragment>
  );
};

export default UserTrucks;
