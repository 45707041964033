import React from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import Users from "./user/pages/Users";
import NewTruck from "./trucks/pages/NewTruck";
import UserTrucks from "./trucks/pages/UserTrucks";
import UpdateTruck from "./trucks/pages/UpdateTruck";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import Auth from "./user/pages/Auth";
import Safety from "./Safety/Safety.js";
import { AuthContext } from "./shared/components/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

const App = () => {
  const { token, login, logout, userId } = useAuth();

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Users />
        </Route>
        <Route path="/:userId/trucks" exact>
          <UserTrucks />
        </Route>
        <Route path="/trucks/new" exact>
          <NewTruck />
        </Route>
        <Route path="/trucks/safety" exact>
          <Safety />
        </Route>
        <Route path="/trucks/:truckId">
          <UpdateTruck />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Users />
        </Route>
        <Route path="/:userId/trucks" exact>
          <UserTrucks />
        </Route>
        <Route path="/auth" exact>
          <Auth />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <MainNavigation />
        <main>{routes}</main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
