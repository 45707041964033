import React, { useState, useContext, useEffect } from "react";
import Card from "../../shared/components/UIElements/Card";
import "./Driver.css";
import Note from "./Notes";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../shared/components/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";
import Modal from "../../shared/components/UIElements/Modal";
import Input from "../../shared/components/FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

const Driver = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  const showDeletewarningHandler = () => {
    setShowConfirmModal(true);
  };

  const showAllNotesHandler = () => {
    setShowAllNotes(true);
  };
  const cancelShowAllNotesHandler = () => {
    setShowAllNotes(false);
  };
  const [formState, inputHandler] = useForm(
    {
      note: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  let adjuster = "goodScore";

  if (((props.speeding / (props.legalSpeed + props.speeding)) * 100).toFixed(2) > 5) {
    adjuster = "unsafeScore";
  }
  if (
    ((props.speeding / (props.legalSpeed + props.speeding)) * 100).toFixed(2) > 2 &&
    ((props.speeding / (props.legalSpeed + props.speeding)) * 100).toFixed(2) < 5
  ) {
    adjuster = "mediumScore";
  }

  const noteSubmitHandler = async (event) => {
    event.preventDefault();
    setShowConfirmModal(false);
    setShowAllNotes(false);

    try {
      console.log("try send");
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/drivers/${props.id}`,
        "PATCH",
        JSON.stringify({
          note: formState.inputs.note,
          id: props.id,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={showConfirmModal}
        header="Add note"
        onCancel={cancelDeleteHandler}
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              {" "}
              CANCEL
            </Button>
            <Button danger onClick={noteSubmitHandler}>
              {" "}
              ADD
            </Button>
          </React.Fragment>
        }
      >
        <Input id="note" element="input" value="" type="text" label="Note" onInput={inputHandler} validators={[VALIDATOR_REQUIRE]} />
      </Modal>
      <Card>
        <div className="driverName"> {props.name}</div>
        <div className="container_scorecard">
          {props.legalSpeed ? (
            <div className="gridItem_scorecard">
              {props.score > 70 ? (
                <div className="goodScore">
                  {" "}
                  Score: <div className="goodScore circleScore"> {props.score}</div>{" "}
                </div>
              ) : (
                <span> </span>
              )}
              {props.score > 50 && props.score <= 70 ? (
                <div className="mediumScore">
                  Score: <div className="mediumScore circleScore"> {props.score}</div>{" "}
                </div>
              ) : (
                <span> </span>
              )}
              {props.score > 35 && props.score <= 49 ? (
                <div className="badScore">
                  Score:
                  <div className="badScore circleScore"> {props.score}</div>{" "}
                </div>
              ) : (
                <span> </span>
              )}
              {props.score > 1 && props.score <= 35 ? (
                <div className="unsafeScore">
                  {" "}
                  Score: <div className="unsafeScore circleScore"> {props.score}</div>{" "}
                </div>
              ) : (
                <span> </span>
              )}
            </div>
          ) : (
            <div> </div>
          )}

          {props.hardBrake < 1 ? (
            <div className=" goodScore gridItem_scorecard">
              {" "}
              <div> Harsh Brake: </div>
              <div className="goodScore circleScore2"> {props.hardBrake}</div>{" "}
            </div>
          ) : (
            <div className="unsafeScore gridItem_scorecard">
              {" "}
              <div> Harsh Brake: </div>
              <div className="unsafeScore circleScore2"> {props.hardBrake}</div>{" "}
            </div>
          )}
          <div className="gridItem_scorecard">
            <div className={adjuster}> Speeding</div>
            <span className={adjuster}>
              {" "}
              {props.speeding && ((props.speeding / (props.legalSpeed + props.speeding)) * 100).toFixed(2)} %{" "}
            </span>
          </div>
        </div>
        <div className="Buttons">
          {" "}
          <Button className="right" onClick={showAllNotesHandler}>
            {" "}
            View{" "}
          </Button>
        </div>
        {props.notes && !showAllNotes && <div> {props.notes[props.notes.length - 1]}</div>}

        {props.notes && (
          <Modal
            show={showAllNotes}
            header="Notes"
            onCancel={cancelShowAllNotesHandler}
            footer={
              <React.Fragment>
                <Button inverse onClick={cancelShowAllNotesHandler}>
                  {" "}
                  CANCEL
                </Button>
                <Button danger onClick={noteSubmitHandler}>
                  {" "}
                  ADD
                </Button>
              </React.Fragment>
            }
          >
            <div>
              {props.notes.reverse().map((note, index = 1) => (
                <Note dnote={note} key={index} />
              ))}
              <Input id="note" element="input" value="" type="text" label="Note" onInput={inputHandler} validators={[VALIDATOR_REQUIRE]} />
            </div>
          </Modal>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Driver;
